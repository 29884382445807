.bubble-cover {
  /*버블 기본 위치 조정*/
  transform: translateX(-220px);
  /*전체 높이 - title, bottom comp*/
  height: calc(100vh - 220px);
}

.bubble-inner:hover {
  box-shadow: 0 0 5px 5px #fff;
}

.bubble-inner.selected {
  transform: scale($expand-scale);
  transform-origin: center;
  transition-duration: .3s;
}

.bubble-inner.bubble-move-center {
  transform: scale(1.0) translateX(0px);
  transform-origin: center;
  transition-duration: .2s;
}

.bubble-title {
  transform: translateY(-100%);
  opacity: 1;
  transition: all .3s;
}

.bubble-title.hide {
  transform: translateY(-100%);
  transition: all .5s;
  opacity: 0;
}

.bubble-move-center {
  transform: scale(1.0) translateX(0px);
  transition-duration: .2s;
}

.bubble-move-left {
  transform: scale(1.0) translateX(-$bubble-move);
  transition-duration: .2s;
}

.bubble-move-right {
  transform: scale(1.0) translateX($bubble-move);
  transition-duration: .2s;
}

.bubble-board {
  height: calc(100vh - 220px);
}

.bubble-board.show {
  z-index: 9999;
  opacity: 1;
  visibility: visible;
  transition-duration: .3s;
}

.bubble-board.hide {
  z-index: -9999;
  opacity: 0;
  visibility: hidden;
  transition-duration: .2s;
}

.board-content {
  font-family: Jamsil, $font-family-serif, $font-family-sans-serif;
  width: 100%;
  margin-bottom: 16px;
  margin-top: 16px;
  padding-left: 32px;
  padding-right: 32px;
  display: block;
}

.board-content span {
  display: block;
  width: 100%;
  margin-bottom: 4px;
  word-break: break-word;
  font-size: 18px;
}

.board-content.colored span {
  display: flex;
  word-break: break-word;
  font-size: 18px;
}

.board-content.colored .square {
  width: 16px;
}

.bubble-bottom {
  /*버블 기본 위치 조정*/
  transform: translateX(-220px);
}

.clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.font-gy {
  font-family: Gwangyang, $font-family-serif, $font-family-sans-serif;
}

.font-js {
  font-family: Jamsil, $font-family-serif, $font-family-sans-serif;
}

.font-kbo {
  font-family: Kbo, $font-family-serif, $font-family-sans-serif;
}
