@font-face {
  font-family: 'Chulae';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/chulae-Regular.woff2') format('woff2'),
  url('../assets/fonts/chulae-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Kbo';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/kbo/KBO-Dia-Gothic_bold.woff') format('woff')
}

@font-face {
  font-family: 'Kbo';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/kbo/KBO-Dia-Gothic_light.woff') format('woff')
}

@font-face {
  font-family: 'Jamsil';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/jamsil/TheJamsil2Light.woff2') format('woff2'),
  url('../assets/fonts/jamsil/TheJamsil2Light.woff') format('woff');
}

@font-face {
  font-family: 'Jamsil';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/jamsil/TheJamsil4Medium.woff2') format('woff2'),
  url('../assets/fonts/jamsil/TheJamsil4Medium.woff') format('woff');
}

@font-face {
  font-family: 'Gwangyang';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/gwangyang/GwangyangSunshineBold.woff2') format('woff2'),
  url('../assets/fonts/gwangyang/GwangyangSunshineBold.woff') format('woff');
}

